<template>
  <v-card class="locked">
    <!-- if searchResults = 5 then height: 484px;  -->
    <v-img class="background" height="228px" position="left"> </v-img>
    <div class="preview">
      <h2 class="white-text m-1">
        View all relevant inventors for this search
      </h2>
      <p class="white-text">
        When you upgrade to PatentPlus Pro, you can access unlimited search
        results, save to custom lists or get notified when new researcher create
        their profile or offer relevant technologies.
      </p>
      <v-btn color="accent">
        <v-icon left dark> mdi-lightning-bolt </v-icon>
        Start free trial
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DummyInventors"
};
</script>

<style lang="scss" scoped>
.locked {
  width: 98.5%;
  background-color: rgba(0, 0, 0, 0.7) !important;
  position: relative;
  .background {
    z-index: -3;
    margin-left: 8px;
    filter: blur(5px);
  }
  .preview {
    width: 66%;
    position: absolute;
    top: 32px;
    left: 16px;
    margin: 8px;
    p {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
}
.results {
  padding: 32px;
}
</style>
