<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row class="higher pt-10 text-center">
        <v-col class="ma-4">
          <p class="display-1 white-text mb-0">
            The Global Technology Transfer Platform
          </p>
        </v-col>
      </v-row>
      <v-row class="higher text-center ma-4">
        <v-col cols="8" lg="8" offset-lg="2">
          <tab-list :items="tabs"></tab-list>
        </v-col>
      </v-row>
      <v-row class="higher subheading text-center mt-4">
        <v-chip class="ma-2 white-text accent">
          License
        </v-chip>
        <p class="white-text">or</p>
        <v-chip class="ma-2 white-text accent">
          Sell
        </v-chip>
        <p class="white-text">
          your patent on the biggest technology marketplace worldwide
        </p>
      </v-row>
      <video :class="videoBackgroundSize" autoplay loop muted>
        <source src="@/assets/video-background.mp4" type="video/mp4" />
      </video>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TabList from "@/components/search/TabList.vue";
import PatentSearch from "@/components/search/tabs/PatentSearch";
import SpinoffSearch from "@/components/search/tabs/SpinoffSearch";
import InventorSearch from "@/components/search/tabs/InventorSearch";
import OrganizationSearch from "@/components/search/tabs/OrganizationSearch";

var tabItems = [
  {
    name: "Patents",
    component: PatentSearch
  },
  {
    name: "Research Organizations",
    component: OrganizationSearch,
    disabled: true
  },
  {
    name: "Inventors",
    component: InventorSearch,
    disabled: true
  },
  {
    name: "Spin-Offs",
    component: SpinoffSearch,
    disabled: true
  }
];

export default {
  name: "TechnologyTransfer",
  components: {
    TabList
  },
  data() {
    return {
      tabs: tabItems
    };
  },
  computed: {
    ...mapGetters(["activeTabIndex"]),
    videoBackgroundSize() {
      return {
        smallVideoSize: this.activeTabIndex == 2 || this.activeTabIndex == 3,
        middleVideoSize: this.activeTabIndex == 1,
        longVideoSize: this.activeTabIndex == 0,
        backgroundVideo: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.backgroundVideo {
  width: 100vw;
  height: 53%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
}
.longVideoSize {
  height: 612px;
}
.middleVideoSize {
  height: 432px;
}
.smallVideoSize {
  height: 520px;
}
// This vertically centers the chip along the text
.subheading {
  height: 72px;
  line-height: 48px;
  display: flex;
  justify-content: center;
}
.higher * {
  z-index: 2;
}
</style>
