<template>
  <v-container>
    <v-row outlined class="mt-1 mr-0 ml-0">
      <patent-filter
        name="Country"
        icon="mdi-earth"
        hide-details="true"
        search
        transferType
      />
      <v-text-field
        class="search-field"
        :error="emptySearch"
        v-model="searchQuery"
        placeholder="Name of the inventor"
        outlined
        clearable
        hide-details="true"
      ></v-text-field>
      <v-col cols="12" lg="2" class="ml-5 no-padding">
        <v-btn color="accent" class="btn-search">
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col
        v-for="filter in filterOptions"
        :key="filter.name"
        cols="12"
        lg="3"
        class="filter-column"
      >
        <patent-filter
          :name="filter.name"
          :items="filter.items"
          :onChange="filter.onChange"
          :icon="filter.icon"
          :select="filter.select"
          :date="filter.date"
          :range="filter.range"
          :search="filter.search"
          :searchPlaceholder="filter.searchPlaceholder"
          class="patent-filter"
        >
        </patent-filter>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatentFilter from "@/components/search/PatentFilter";

export default {
  name: "InventorSearch",
  components: {
    PatentFilter
  },
  data() {
    return {
      searchQuery: ""
    };
  },
  computed: {
    emptySearch() {
      return this.queryIsEmpty && this.ROSearchStarted && this.filterIsEmpty;
    },
    queryIsEmpty() {
      return this.searchQuery === "";
    },
    filterIsEmpty() {
      return this.activeResearchOrganizationFilters.country == ""
        ? true
        : false;
    },
    filterOptions() {
      return [
        {
          name: "Research Focus",
          select: true,
          icon: "mdi-lightbulb-group",
          items: this.patentStatus,
          onChange: this.updateStatusFilter
        },
        {
          name: "Experience",
          range: true,
          icon: "mdi-badge-account",
          items: this.uniqueClassifications,
          onChange: this.updateClassificationFilter
        },
        {
          name: "Organization",
          search: true,
          icon: "mdi-office-building-outline",
          searchPlaceholder: "Search Jurisdiction...",
          items: this.uniqueCountries,
          onChange: this.updateCountryFilter
        },
        {
          name: "Climate Tech",
          select: true,
          icon: "mdi-pine-tree",
          onChange: this.updateOwnerFilter
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.no-padding {
  padding: 0px;
}
.btn-search {
  width: 100%;
  height: 56px !important;
}
.search-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}
.patent-filter {
  max-width: 100%;
}
.filter-column {
  pointer-events: none;
}
</style>
