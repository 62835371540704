<template>
  <v-container fluid>
    <v-row outlined class="mt-1 mr-0 ml-0">
      <patent-filter
        name="Transfer Types"
        :items="transferTypes"
        icon="mdi-arrow-left-right"
        hide-details="true"
        select
        transferType
        :onChange="this.callbackDummy"
      />
      <v-text-field
        class="search-field"
        v-model="patentSearchQuery"
        placeholder="What: Title, Owner, Status, CPC"
        outlined
        clearable
        hide-details="true"
        @keyup.enter.native="startPatentSearch"
        @click:clear="resetSearchQuery"
      ></v-text-field>
      <v-col cols="12" lg="2" class="ml-5 no-padding">
        <v-btn color="accent" class="btn-search" @click="startPatentSearch">
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col
        v-for="filter in filterOptions"
        :key="filter.name"
        cols="12"
        lg="3"
        class="filter-column"
      >
        <patent-filter
          :name="filter.name"
          :items="filter.items"
          :onChange="filter.onChange"
          :icon="filter.icon"
          :select="filter.select"
          :date="filter.date"
          :range="filter.range"
          :search="filter.search"
          :searchPlaceholder="filter.searchPlaceholder"
          class="patent-filter"
        >
        </patent-filter>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PatentFilter from "@/components/search/PatentFilter";

export default {
  name: "PatentSearch",
  props: {
    filterOptionsProp: {
      required: false,
    },
  },
  components: {
    PatentFilter,
  },
  data() {
    return {
      patentStatus: ["Grant", "Application"],
      transferTypes: ["Buy", "License", "Exclusive License", "Co-Development"],
    };
  },
  computed: {
    ...mapGetters([
      "patentSearchQuery",
      "uniqueInventors",
      "uniqueClassifications",
      "uniqueCountries",
      "uniqueOwner",
      "uniqueIndustry",
      "patentSearchStarted",
      "activePatentFilters",
    ]),
    patentSearchQuery: {
      get() {
        return this.$store.getters.patentSearchQuery;
      },
      set(newQuery) {
        this.setPatentSearchQuery(newQuery);
      },
    },
    filterOptions() {
      if (this.filterOptionsProp) {
        return this.filterOptionsProp;
      }
      return this.defaultOptions;
    },
    defaultOptions() {
      return [
        {
          name: "Patent Status",
          select: true,
          icon: "mdi-file-document-outline",
          items: this.patentStatus,
          onChange: this.updateStatusFilter,
        },
        {
          name: "Tech. Readiness",
          range: true,
          icon: "mdi-wrench",
          items: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          onChange: this.updateTechnologyReadinessFilter,
        },
        {
          name: "Countries",
          search: true,
          icon: "mdi-earth",
          searchPlaceholder: "Search Jurisdiction...",
          items: this.uniqueCountries,
          onChange: this.updateCountryFilter,
        },
        {
          name: "Owners",
          search: true,
          icon: "mdi-account-cash-outline",
          searchPlaceholder: "Search Owners (US)...",
          items: this.uniqueOwner,
          onChange: this.updateOwnerFilter,
        },
        {
          name: "Priority Date",
          date: true,
          icon: "mdi-calendar-month",
          onChange: this.updateDateFilter,
        },
        {
          name: "Inventors",
          search: true,
          icon: "mdi-briefcase-account",
          searchPlaceholder: "Search Inventors...",
          items: this.uniqueInventors,
          onChange: this.updateInventorFilter,
        },
        {
          name: "Climate Tech",
          select: true,
          icon: "mdi-pine-tree",
          searchPlaceholder: "Search Climate Tech...",
          items: ["Yes", "No"],
          onChange: this.updateClimateTechFilter,
        },
        {
          name: "Industry",
          select: true,
          icon: "mdi-office-building-cog",
          searchPlaceholder: "Search Industry...",
          items: this.uniqueIndustry,
          onChange: this.updateIndustryFilter,
        },
      ];
    },
    emptySearch() {
      return this.queryIsEmpty && this.filterIsEmpty;
    },
    queryIsEmpty() {
      return this.patentSearchQuery === "";
    },
    filterIsEmpty() {
      let hasFilterWithActiveStatus = false;
      let filters = Object.keys(this.activePatentFilters);
      filters.forEach((filter) => {
        this.activePatentFilters[filter].forEach((filterElement) => {
          if (filterElement.status == true) {
            hasFilterWithActiveStatus = true;
          }
        });
      });
      if (!isNaN(this.activePatentFilters.technologyReadiness[0])) {
        hasFilterWithActiveStatus = true;
      }
      // Dates are within an array and not an object
      if (this.activePatentFilters.dates.length !== 0) {
        hasFilterWithActiveStatus = true;
      }
      return !hasFilterWithActiveStatus;
    },
    filterLength() {
      return Object.keys(this.activePatentFilters).length;
    },
  },
  methods: {
    ...mapActions([
      "fetchPatentList",
      "updateStatusFilter",
      "updateClassificationFilter",
      "updateCountryFilter",
      "updateOwnerFilter",
      "updateDateFilter",
      "updateApplicationFieldFilter",
      "updateTechnologyReadinessFilter",
      "updateInventorFilter",
      "updateClimateTechFilter",
      "updateIndustryFilter",
      "resetPatentSearchQuery",
      "setPatentSearchQuery",
    ]),
    startPatentSearch() {
      if (this.queryIsEmpty) {
        this.patentSearchQuery = " ";
      }
      this.fetchPatentList(this.patentSearchQuery);
      this.patentSearchQuery = "";
    },
    resetSearchQuery() {
      // Vuetify still assigns it to null therefore the timeout
      setTimeout(() => {
        this.resetPatentSearchQuery();
      }, 100);
    },
    // TODO implement search result filtering for transfer types
    callbackDummy() {
      console.log("transfer type callback dummy");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-padding {
  padding: 0px;
}
.btn-search {
  width: 100%;
  height: 56px !important;
}
.search-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}
.filter-column {
  padding: 0px;
  padding-left: 12px;
  padding-right: 12px;
  height: 74px;
}
.patent-filter {
  max-width: 100%;
}
</style>
