import Vuex from "vuex";
import Vue from "vue";
import filter from "./modules/filter";
import search from "./modules/search";
import tabList from "./modules/tab-list";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    filter,
    search,
    tabList,
    auth
  }
});
