<template>
  <v-container>
    <!-- TODO check if logged in first and display a loading indicator
      to prevent an ugly transition to the home page -->
    <v-col cols="4">
      <div class="background primary"></div>
      <v-row class="mt-8">
        <v-img class="logo mr-3" src="@/assets/wre.png"></v-img>
        <h2 class="logo-text white-text">PatentPlus</h2>
      </v-row>
    </v-col>
    <v-row class="content" align="center">
      <v-col class="dark" cols="4">
        <h3 class="mb-5 white-text">Do you want to register or book a demo?</h3>
        <p class="white-text">
          Get access to the PatentPlus platform to discover available patents,
          innovative technologies, pioneering research organizations,
          best-in-class inventors, and deep-tech spin-offs.
        </p>

        <v-dialog v-model="showSignupDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              class="btn-primary"
              outlined
              depressed
              v-bind="attrs"
              v-on="on"
            >
              Sign up
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Get Started</span>
              <v-spacer></v-spacer>
              <v-icon @click="showSignupDialog = false">mdi-close</v-icon>
            </v-card-title>
            <form
              action="https://formcarry.com/s/NoQvIDNIieK"
              method="POST"
              accept-charset="UTF-8"
              ref="signupform"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" class="pb-0 pl-0">
                      <v-text-field
                        label="First Name"
                        type="text"
                        name="firstName"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0 pr-0">
                      <v-text-field
                        label="Last Name"
                        type="text"
                        name="lastName"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        label="Organisation"
                        type="text"
                        name="organization"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        label="Email"
                        type="email"
                        name="email"
                        outlined
                        required
                      >
                      </v-text-field>
                      <input type="hidden" name="_gotcha" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-3 mb-1">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" outlined @click="signUp">
                      Submit
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-container>
              </v-card-text>
            </form>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="5" offset="2">
        <amplify-authenticator usernameAlias="email">
          <amplify-sign-in
            slot="sign-in"
            header-text="Sign in to access the PatentPlus platform."
            username-alias="email"
          >
            <!-- This hides the sign up button and aligns the sign in button to the left -->
            <div
              slot="secondary-footer-content"
              class="secondary-footer"
              style="position: absolute;"
            ></div>
          </amplify-sign-in>
          <amplify-sign-out></amplify-sign-out>
          <amplify-forgot-password
            username-alias="email"
            slot="forgot-password"
          ></amplify-forgot-password>
        </amplify-authenticator>
        <v-row class="ml-2 mt-2 mb-2">
          <small>
            By continuing to use the site you agree to the
            <a href="https://www.patentplus.io/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </small>
        </v-row>
      </v-col>
    </v-row>
    <v-col cols="5" offset="6">
      <div class="ml-2 footer text-caption">
        <span>© PatentPlus. All Rights Reserved</span>
        <span>
          |
          <a href="https://www.patentplus.io/privacy-policy" target="_blank">
            General Terms & Privacy Policy
          </a>
        </span>
        <span>
          |
          <a href="https://www.patentplus.io/imprint-test" target="_blank">
            Imprint
          </a>
        </span>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "Login",
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      showSignupDialog: false
    };
  },
  watch: {
    authState: async function(state) {
      if (state === "signedin") {
        await this.login();
        this.$router.push("Home");
      }
    }
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
  methods: {
    ...mapActions(["login"]),
    signUp() {
      this.$refs.signupform.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 460px;
}
.background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  height: 100vh;
  width: 45vw;
  background: $color-primary;
}
.content {
  height: 80vh !important;
  position: relative;
}
.logo {
  max-height: 40px;
  max-width: 40px !important;
  border-radius: $border-radius;
}
.logo,
.logo-text {
  position: relative;
}
a {
  font-weight: 500;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.footer a {
  font-weight: 400;
}
.dark a {
  text-decoration: underline;
  color: $color-white !important;
}
.btn-primary {
  width: 160px;
  height: 48px !important;
  border-radius: 0px;
}
</style>
