<template>
  <v-container>
    <v-row outlined class="mt-1 mr-0 ml-0">
      <patent-filter
        name="Country"
        :items="filterOptions[0].items"
        icon="mdi-earth"
        hide-details="true"
        search
        transferType
        :onChange="filterOptions[0].onChange"
      />
      <v-text-field
        class="search-field"
        :error="emptySearch"
        v-model="searchQuery"
        placeholder="Research focus or organization"
        outlined
        clearable
        hide-details="true"
        @keyup.enter.native="startROSearch"
        @click:clear="resetSearchQuery"
      ></v-text-field>
      <v-col cols="12" lg="2" class="ml-5 no-padding">
        <v-btn color="accent" class="btn-search" @click="startROSearch">
          Search
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PatentFilter from "@/components/search/PatentFilter";

export default {
  name: "OrganizationSearch",
  components: {
    PatentFilter
  },
  data() {
    return {
      searchQuery: "",
      ROSearchStarted: false
    };
  },
  mounted() {
    this.startROSearch();
  },
  computed: {
    ...mapGetters(["uniqueROCountries", "activeResearchOrganizationFilters"]),
    filterOptions() {
      return [
        {
          name: "Country",
          search: true,
          icon: "mdi-file-document-outline",
          items: this.uniqueROCountries,
          onChange: this.updateROCountry
        }
      ];
    },
    emptySearch() {
      return this.queryIsEmpty && this.ROSearchStarted && this.filterIsEmpty;
    },
    queryIsEmpty() {
      return this.searchQuery === "";
    },
    filterIsEmpty() {
      return this.activeResearchOrganizationFilters.country == ""
        ? true
        : false;
    }
  },
  methods: {
    ...mapActions(["fetchROList", "updateROCountry"]),
    async startROSearch() {
      this.ROSearchStarted = true;
      await this.fetchROList(this.searchQuery);
    },
    resetSearchQuery() {
      // Vuetify still assigns it to null therefore the timeout
      setTimeout(() => {
        this.searchQuery = "";
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.no-padding {
  padding: 0px;
}
.btn-search {
  width: 100%;
  height: 56px !important;
}
.search-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}
.patent-filter {
  max-width: 100%;
}
</style>
