<template>
  <v-card height="328px">
    <v-card-text class="pb-0">
      <h3>Recommended Patents</h3>
      <p>Based on your profile, searches and your patent portfolio</p>
    </v-card-text>
    <v-img
      src="@/assets/searchresults4.png"
      class="background align-end"
      gradient="to bottom, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)"
      height="254px"
      position="left"
    >
      <v-card-text>
        <v-row class="d-flex mt-15">
          <v-col cols="12" class="text-center p-0">
            <v-icon color="accent" class="icon mb-5">
              mdi-lightbulb-on-outline
            </v-icon>
            <p>Your profile has no recommendations yet</p>
            <v-btn text color="accent" class="text-center">
              Complete profile
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "RecommendedPatents",
};
</script>

<style lang="scss" scoped>
.background {
  background-repeat: no-repeat;
}
.premium-preview {
  p {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon {
  font-size: 28px !important;
}
</style>
