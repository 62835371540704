export default {
    state: {
        loggedIn: false
    },
    actions: {
        async login({ commit }) {
            commit("login");
        },
        async logout({ commit }) {
            commit("logout");
        }
    },
    mutations: {
        login: state => (state.loggedIn = true),
        logout: state => (state.loggedIn = false)
    },
    getters: {
        loggedIn: state => state.loggedIn
    }
};
