<template>
  <v-container class="mt-12 mb-16">
    <v-row v-if="isLoading">
      <p>Loading ...</p>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card class="header pt-8 pl-9 pb-15">
          <v-icon class="ml-12" left color="primary" @click="$router.go(-1)">
            mdi-arrow-left
          </v-icon>
          <v-row justify="space-around" align="center" class="pa-4">
            <v-col class="mt-4 pl-1" cols="2">
              <div class="logo-background">
                <img :src="logoUrl" class="ma-2" />
              </div>
            </v-col>
            <v-col class="text-left mt-4 mr-n16 ml-n10" cols="5">
              <h3 class="primary-text mb-2">{{ this.ro.ro_name_english }}</h3>
              <p class="primary-subtext mb-2">
                {{ this.ro.ro_country }},
                {{ this.ro.ro_city }}
              </p>
              <a :href="this.ro.tto_website" class="accent-text"
                >View TTO website</a
              >
            </v-col>

            <v-col class="mr-n11 mt-10" cols="4" color="primary-text">
              <h3 class="ml-n4 primary-text">
                TTO Contact
                <v-icon class="mb-1" dense color="primary">
                  mdi-information
                </v-icon>
              </h3>
              <div class="ml-n4 mt-2 ml-n1">
                <p class="mb-2 primary-subtext" v-if="this.ro.tto_name">
                  <v-icon class="mt-n1 ma-1" dense color="primary">
                    mdi-account
                  </v-icon>
                  {{ this.ro.tto_name }}
                </p>

                <p class="mb-1 primary-subtext">
                  <v-icon class="mt-n1 ma-1" dense color="primary">
                    mdi-phone
                  </v-icon>
                  {{ this.ro.tto_phone }}
                </p>

                <p class="primary-subtext">
                  <v-icon class="mb-2 ma-1" dense color="primary">
                    mdi-email
                  </v-icon>
                  {{ this.ro.tto_mail }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row justify="space-around" class="pb-2">
            <v-col class="ml-4" cols="7">
              <h3 class="mb-3">About Us</h3>
              <div>
                {{ this.aboutUsText }}

                <a
                  href="javascript:;"
                  @click="toggleAboutUs"
                  v-if="aboutUsCollapsed"
                >
                  see more
                </a>
                <a href="javascript:;" @click="toggleAboutUs" v-else>
                  show less
                </a>
              </div>
            </v-col>
            <v-col
              class="mt-n1 mr-16 ml-n6"
              cols="3"
              color="primary-text"
              display="none"
            >
              <h3 class="ml-n4">Research Focus</h3>

              <v-row>
                <v-col class="pr-0 pt-4 pb-0 pb-4 pa-0">
                  <p
                    class="mt-2 mb-2"
                    v-for="roWords in focusWords"
                    :key="roWords"
                    :value="roWords"
                  >
                    {{ roWords }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div class="searchbar mt-8">
          <v-card class="pa-4">
            <patent-search :filterOptionsProp="patentFilterOptions">
            </patent-search>
          </v-card>
        </div>
        <div class="patents mt-8 mb-6">
          <div class="search-result">
            <v-row justify="space-between">
              <v-col cols="3">
                <p class="ma-2">
                  Found {{ patentList.length }} available patents
                </p>
              </v-col>
              <v-col cols="3" offset="6">
                <v-chip class="ma-2 ml-8" color="accent"> All </v-chip>
                <v-chip class="ma-2"> Verified </v-chip>
                <v-chip class="ma-2"> Pending </v-chip>
              </v-col>
            </v-row>
            <v-row v-if="patentSearchStarted" class="mr-3">
              <v-cols cols="12">
                <r-o-search-results cols="12"></r-o-search-results>
              </v-cols>
              <dummy-search-results
                class="ml-3"
                v-if="patentList.length > 0"
              ></dummy-search-results>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PatentSearch from "@/components/search/tabs/PatentSearch";
import ROSearchResults from "@/components/search/ROSearchResults.vue";
import DummySearchResults from "@/components/dummy/SearchResults.vue";

export default {
  name: "ResearchOrganization",
  props: ["id"],
  components: {
    PatentSearch,
    ROSearchResults,
    DummySearchResults,
  },
  data() {
    return {
      ro: {},
      isLoading: true,
      aboutUsCollapsed: true,
      patentStatus: ["Grant", "Application"],
    };
  },
  created() {
    this.loadResearchOrganization();
    this.loadPatents();
  },
  computed: {
    ...mapGetters([
      "currentResearchOrganization",
      "patentList",
      "patentSearchStarted",
      "uniqueInventors",
      "uniqueClassifications",
      "uniqueCountries",
      "uniqueROCountries",
    ]),
    logoUrl() {
      return `https://research-organizations.s3.eu-central-1.amazonaws.com/logos/${this.ro.ro_logo}`;
    },
    aboutUsText() {
      var charLimit = 250;
      if (this.aboutUsCollapsed && this.ro.ro_description) {
        return this.ro.ro_description.substring(0, charLimit) + " ..."; // abuot four lines
      } else {
        return this.ro.ro_description;
      }
    },
    patentFilterOptions() {
      return [
        {
          name: "Patent Status",
          select: true,
          icon: "mdi-file-document-outline",
          items: this.patentStatus,
          onChange: this.updateStatusFilter,
        },
        {
          name: "Tech. Readiness",
          range: true,
          icon: "mdi-wrench",
          items: this.uniqueClassifications,
          onChange: this.updateClassificationFilter,
        },
        {
          name: "Countries",
          search: true,
          icon: "mdi-earth",
          searchPlaceholder: "Search Jurisdiction...",
          items: this.uniqueCountries,
          onChange: this.updateCountryFilter,
        },
        {
          name: "Owners",
          search: true,
          icon: "mdi-account-cash-outline",
          searchPlaceholder: "Search Owners (US)...",
          items: this.uniqueOwner,
          onChange: this.updateOwnerFilter,
        },
        {
          name: "Priority Date",
          date: true,
          icon: "mdi-calendar-month",
          onChange: this.updateDateFilter,
        },
        {
          name: "Inventors",
          search: true,
          icon: "mdi-briefcase-account",
          searchPlaceholder: "Search Inventors...",
          items: this.uniqueInventors,
          onChange: this.updateInventorFilter,
        },
        {
          name: "Climate Tech",
          select: true,
          icon: "mdi-pine-tree",
          searchPlaceholder: "Search Climate Tech...",
          items: ["Yes", "No"],
          onChange: this.updateInventorFilter,
        },
        {
          name: "Industry",
          select: true,
          icon: "mdi-office-building-cog",
          searchPlaceholder: "Search Industry...",
          items: this.uniqueOwner,
          onChange: this.updateInventorFilter,
        },
      ];
    },
    focusWords() {
      let result = [];
      if (this.ro) {
        result = [
          "1. " + this.ro.research_focus_1,
          "2. " + this.ro.research_focus_2,
          "3. " + this.ro.research_focus_3,
        ];
      }
      return result;
    },
  },
  methods: {
    ...mapActions([
      "fetchRO",
      "fetchPatentListByRO",
      "updateStatusFilter",
      "updateClassificationFilter",
      "updateCountryFilter",
      "updateInventorFilter",
    ]),
    async loadResearchOrganization() {
      await this.fetchRO(this.id);
      this.ro = this.currentResearchOrganization[0];
      this.isLoading = false;
    },
    async loadPatents() {
      const roName = this.currentResearchOrganization[0].ro_name_english;
      await this.fetchPatentListByRO(roName);
    },
    toggleAboutUs() {
      this.aboutUsCollapsed = !this.aboutUsCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-radius: $border-radius;
  .logo-background {
    border-radius: $border-radius;
    border: 1px solid $color-white;
    img {
      height: 152px;
      width: 152px;
      display: block;
      margin: auto;
      object-fit: contain;
      border-radius: $border-radius;
    }
  }
  .short-text {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: block;
  }
}
.btn-search {
  width: 100%;
  height: 56px !important;
}
.search-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}
.patent-filter {
  max-width: 100%;
}
.no-decoration {
  text-decoration: none;
}
.cpc-chip {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}
</style>
