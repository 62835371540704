<template>
  <v-card class="research-organization" width="100%">
    <div class="header">
      <v-card-title class="ml-4">
        <v-row justify="center" class="logo-background">
          <img :src="profilePicture" />
        </v-row>
      </v-card-title>
    </div>

    <v-card-text>
      <div class="ml-2 mt-2 mb-4">
        <h3>{{ this.name }}, {{ this.organization }}</h3>
        <p class="mb-0">{{ this.city }}, {{ this.country }}</p>
        <p class="mb-0">
          {{ this.researchFocus }}: {{ this.patentCount }} patents
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ResearchOrganization",
  props: ["item"],
  data() {
    return {
      name: this.item.name,
      profile: this.item.image,
      organization: this.item.organization,
      country: this.item.country,
      city: this.item.city,
      patentCount: this.item.patentCount,
      researchFocus: this.item.focus
    };
  },
  computed: {
    profilePicture() {
      return require(`@/assets/faces/${this.profile}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.research-organization {
  .header {
    height: 64px;
    position: relative;
    margin-bottom: 16px;
    // background-color: $color-primary-light !important;
    .logo-background {
      top: 32px;
      height: 64px;
      width: 64px;
      position: absolute;
      background: $color-white;
      border-radius: $border-radius;
      img {
        height: 64px;
        width: 64px;
        position: absolute;
        object-fit: contain;
        border-radius: $border-radius;
      }
    }
  }
}
</style>
