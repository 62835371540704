<template>
  <v-card
    class="research-organization"
    width="100%"
    height="200"
    @click="navigateToResearchOrganization"
  >
    <div class="header">
      <v-card-title class="ml-4">
        <v-row justify="center" class="logo-background">
          <img :src="logoUrl" />
        </v-row>
      </v-card-title>
    </div>

    <v-card-text>
      <div class="ml-2 mt-2 mb-4">
        <h3>{{ this.name }}</h3>
        <p>{{ this.city }}, {{ this.country }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ResearchOrganization",
  props: ["item"],
  data() {
    return {
      id: this.item.id,
      name: this.item.ro_name_english,
      logo: this.item.ro_logo,
      country: this.item.ro_country,
      city: this.item.ro_city,
    };
  },
  computed: {
    logoUrl() {
      return `https://research-organizations.s3.eu-central-1.amazonaws.com/logos/${this.logo}`;
    },
  },
  methods: {
    navigateToResearchOrganization() {
      this.$router.push({
        name: "Research-Organization",
        params: { id: this.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.research-organization {
  .header {
    height: 64px;
    position: relative;
    margin-bottom: 16px;
    // background-color: $color-primary-light !important;
    .logo-background {
      top: 32px;
      height: 64px;
      width: 64px;
      position: absolute;
      background: $color-white;
      border-radius: $border-radius;
      img {
        height: 64px;
        width: 64px;
        position: absolute;
        object-fit: contain;
        border-radius: $border-radius;
      }
    }
  }
}
</style>
