export default {
  state: {
    activeTabIndex: 0,
    activeTab: {},
    initialTab: {}
  },
  actions: {
    async setActiveTabIndex({ commit }, newIndex) {
      commit("setActiveTabIndex", newIndex);
    },
    async setActiveTab({ commit }, newTab) {
      commit("setActiveTab", newTab);
    },
    async setInitialTab({ commit }, tab) {
      commit("setInitialTab", tab)
    },
    async setActiveTabToInitialTab({ commit, getters }) {
      const tab = getters.initialTab;
      commit("setActiveTabIndex", 0)
      commit("setActiveTab", tab)
    },
    async resetApplicationState({ commit }) {
      commit("resetPatentSearchQuery")
      commit("resetPatentSearchState")
    },

  },
  mutations: {
    setActiveTabIndex: (state, payload) => (state.activeTabIndex = payload),
    setActiveTab: (state, payload) => (state.activeTab = payload),
    setInitialTab: (state, payload) => (state.initialTab = payload),
  },
  getters: {
    activeTabIndex: state => state.activeTabIndex,
    activeTab: state => state.activeTab,
    initialTab: state => state.initialTab
  }
};
