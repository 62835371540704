<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        v-bind="attrs"
        v-on="on"
        class="filter-chip mx-auto col-3"
        :class="{ 'active-patent-filter': isFilterActive }"
        outlined
        @click="toggleMenuState"
        v-click-outside="{
          handler: closeMenuState,
          include: getIncludedClickArea,
        }"
      >
        <v-row class="pl-1 pt-1">
          <v-col cols="2">
            <v-icon aria-hidden="false">{{ innerIcon }}</v-icon>
          </v-col>
          <v-col cols="8" align="start" class="pl-5 pr-0">
            <p class="filter-label">{{ label }}</p>
          </v-col>
          <v-col cols="1" class="pl-0">
            <v-icon aria-hidden="false" v-if="isFilterOpen">
              mdi-menu-up
            </v-icon>
            <v-icon aria-hidden="false" v-else> mdi-menu-down </v-icon>
          </v-col>
        </v-row>
      </v-card>
    </template>
    <v-list max-height="300" class="included-click-area">
      <div v-if="search">
        <v-text-field
          v-model="searchFilterQuery"
          :placeholder="placeholder"
          append-icon="mdi-magnify"
          class="filter-searchbar mt-3 ml-5"
          small
          outlined
          dense
        ></v-text-field>
        <v-list-item
          v-for="item in filteredSearchValues"
          :key="item.name"
          class="white pa-0"
        >
          <v-list-item-content class="ml-5">
            <v-row align="center">
              <v-col cols="2" class="pa-0">
                <v-checkbox
                  v-model="item.status"
                  @change="callback(activeFilter)"
                  :ripple="false"
                  class="ml-3"
                ></v-checkbox>
              </v-col>
              <v-col cols="8" class="pa-0 pl-1">
                <p class="body-2 mb-0" v-html="itemName(item.name)"></p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="date">
        <v-col cols="12" class="pa-0 pt-3 pl-3 pr-4">
          <v-menu
            v-model="fromMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                @change="setDate"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              @change="setDate"
              @input="fromMenu = false"
              type="year"
              min="2000"
              max="2021"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="pa-0 pl-3 pr-4">
          <v-menu
            v-model="toMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @change="setDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              @input="toMenu = false"
              @change="setDate"
              type="year"
              min="2000"
              max="2021"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </div>
      <div v-if="select">
        <v-list-item
          v-for="item in filteredSearchValues"
          :key="item.name"
          class="white pa-0"
        >
          <v-list-item-content class="pa-0 ml-5">
            <v-row align="center">
              <v-col cols="2" class="pa-0">
                <v-checkbox
                  v-model="item.status"
                  :ripple="false"
                  class="ml-3"
                  @change="callback(activeFilter)"
                ></v-checkbox>
              </v-col>
              <v-col cols="8" class="pa-0 pl-1">
                <p class="body-2 mb-0" v-html="itemName(item.name)"></p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="range" class="range">
        <v-range-slider
          class="pa-3 pt-8"
          v-model="technologyReadinessLevel"
          label="TRL"
          min="1"
          max="9"
          thumb-label="always"
          step="1"
          ticks
          @change="setTechLevel"
        />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PatentFilter",
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    items: {
      required: false,
    },
    onChange: {
      type: Function,
      required: true,
    },
    date: {
      type: Boolean,
    },
    search: {
      type: Boolean,
    },
    searchPlaceholder: {
      type: String,
    },
    select: {
      type: Boolean,
    },
    range: {
      type: Boolean,
    },
    transferType: {
      type: Boolean,
    },
  },
  data() {
    return {
      label: this.name,
      innerIcon: this.icon,
      content: [],
      callback: this.onChange,
      technologyReadinessLevel: [2, 5],
      fromDate: new Date().toISOString().substr(0, 7),
      toDate: new Date().toISOString().substr(0, 7),
      fromMenu: "",
      toMenu: "",
      isFilterOpen: false,
      searchFilterQuery: "",
    };
  },
  // transform Vuex object structure for search and multiselect
  created() {
    if (this.search || this.select) {
      this.items.forEach((item) => {
        this.content.push({
          name: item,
          status: false,
        });
      });
    }
  },
  computed: {
    ...mapGetters(["activePatentFilters"]),
    placeholder() {
      let placeholder = this.searchPlaceholder;
      if (placeholder) {
        if (placeholder.length > 22) {
          return placeholder.substring(0, 22) + "...";
        }
        return placeholder;
      }
      return "";
    },
    filteredSearchValues() {
      if (this.content) {
        return this.content.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(this.searchFilterQuery.toLowerCase());
        });
      }
      return [];
    },
    activeFilter() {
      return this.content.filter((item) => item.status === true);
    },
    isFilterActive() {
      if (this.name == "Patent Status") {
        return this.activePatentFilters.status.length != 0;
      } else if (this.name == "Tech. Readiness") {
        return this.activePatentFilters.technologyReadiness.length != 0;
      } else if (this.name == "Countries") {
        return this.activePatentFilters.countries.length != 0;
      } else if (this.name == "Owners") {
        return this.activePatentFilters.owner.length != 0;
      } else if (this.name == "Priority Date") {
        return this.activePatentFilters.dates.length != 0;
      } else if (this.name == "Inventors") {
        return this.activePatentFilters.inventors.length != 0;
      } else if (this.name == "Climate Tech") {
        return this.activePatentFilters.climateTech.length != 0;
      } else if (this.name == "Industry") {
        return this.activePatentFilters.industry.length != 0;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleMenuState() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    closeMenuState() {
      this.isFilterOpen = false;
    },
    // This is a helper function, see more here: https://vuetifyjs.com/en/directives/click-outside/
    getIncludedClickArea() {
      return [document.querySelector(".included-click-area")];
    },
    setDate() {
      this.callback([
        this.fromDate.substring(0, 4),
        this.toDate.substring(0, 4),
      ]);
    },
    setTechLevel() {
      this.callback(this.technologyReadinessLevel);
    },
    // TODO renders multiple non visble and empty <p> tags
    itemName(value) {
      let resultHTML = "";
      const words_per_line = 3;
      for (let i = 0; i < value.length; i += words_per_line) {
        const truncatedValue = value
          .split(" ")
          .splice(i, i + words_per_line)
          .join(" ");
        resultHTML += `<p class="mb-0">${truncatedValue}</p>`;
      }
      return resultHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-chip {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: $border-radius !important;
}
.filter-chip {
  height: 56px;
  border: thin solid $color-border;
  color: $color-secondary-text;
  border-radius: $border-radius;
}
.filter-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.filter-searchbar {
  height: 52px;
  width: 82.5%;
  font-size: 12px !important;
}
.active-patent-filter {
  border: 1px solid $color-primary;
}
</style>
