import algoliasearch from "algoliasearch";

const applicationID = "IP1FKTDG5V";
const indexName = "fundraising_index";
const apiKey = process.env.VUE_APP_ALGOLIA_KEY;
const client = algoliasearch(applicationID, apiKey);
const index = client.initIndex(indexName);

// TODO implement real API Gateway call for ROs
import RO_LIST from "@/store/researchOrganizations.json";
import INVENTOR_LIST from "@/store/inventors.json";
import SPINOFF_LIST from "@/store/spinoffs.json";

export default {
  state: {
    currentPatent: {},
    currentResearchOrganization: {},
    patentList: {},
    ROList: RO_LIST.researchOrganizations,
    InventorList: INVENTOR_LIST.inventors,
    SpinoffList: SPINOFF_LIST.spinoffs,
    patentSearchQuery: "",
    patentSearchStarted: false,
    ROSearchStarted: false,
    ROListChangeVar: 0
  },
  actions: {
    async fetchPatent(state, objectID) {
      let patent;
      // check if patentList is not empty before filtering
      if (state.patentList) {
        patent = state.patentList.filter(p => p.objectID === objectID)[0];
      }
      // load patent if it's not found in cache
      if (!patent) {
        await index.getObject(objectID).then(object => {
          patent = object;
        });
      }
      state.commit("setCurrentPatent", patent);
    },
    async fetchRO(state, id) {
      // TODO implement real request with aws api gateway, lambda and dynamodb
      const currentRO = RO_LIST.researchOrganizations.filter(
        ro => ro.id === id
      );
      state.commit("setCurrentResearchOrganization", currentRO);
    },
    async fetchPatentList({ commit, dispatch, getters }, query) {
      const filters = getters.activePatentFilters;
      let algoliaFilter = "";
      const filterCategories = [
        "status",
        "cpcClassification",
        "countries",
        "owner",
        "applicationFields",
        "inventors",
        "climateTech",
        "industry"
      ];

      // Reference: https://www.algolia.com/doc/api-reference/api-parameters/filters
      filterCategories.forEach(category => {
        let values = filters[category];
        if (values) {
          for (let i = 0; i < values.length; i++) {
            algoliaFilter += `${category}:"${values[i].name}" AND `;
          }
        }
      });
      // The string structures behind tech-readiness (value) and date (range) are just built different 🥶
      if (
        filters.technologyReadiness &&
        !isNaN(filters.technologyReadiness[0])
      ) {
        algoliaFilter += `technologyReadiness > ${filters
          .technologyReadiness[0] - 1} AND `;
        algoliaFilter += `technologyReadiness < ${filters
          .technologyReadiness[1] + 1} AND `;
      } else if (filters.dates.length !== 0) {
        algoliaFilter += `priority:${filters.dates[0]} TO ${filters.dates[1]} AND `;
      }
      algoliaFilter = algoliaFilter.slice(0, -5);

      index
        .search(query, {
          filters: algoliaFilter,
          hitsPerPage: 100
        })
        .then(({ hits }) => {
          commit("startPatentSearch");
          commit("setPatentList", hits);
          dispatch("updateCurrentPatentFilterValues", hits);
        });
    },
    async fetchPatentListByRO({ commit }, researchOrganization) {
      index
        .search("", {
          filters: `owner: "${researchOrganization}"`
        })
        .then(({ hits }) => {
          commit("startPatentSearch");
          commit("setPatentList", hits);
        });
    },
    async fetchROList({ commit, dispatch, getters }, query) {
      // TODO implement real API Gateway call for ROs
      const countryFilter = getters.activeResearchOrganizationFilters;
      console.log("ignore -- " + countryFilter);

      // RO Filtering
      const results = RO_LIST.researchOrganizations.filter(function (ro) {
        return (
          ro.ro_name_english.toLowerCase().includes(query.toLowerCase()) ||
          ro.research_focus_1.toLowerCase().includes(query.toLowerCase()) ||
          ro.research_focus_2.toLowerCase().includes(query.toLowerCase()) ||
          ro.research_focus_3.toLowerCase().includes(query.toLowerCase())
        );
      });

      commit("startROSearch");
      commit("setROList", results);
      commit("setROListChangeVar", getters.ROListChangeVar + 1);
      dispatch("updateROCountry", getters.uniqueROCountries);
    },
    async setPatentSearchQuery({ commit }, newQuery) {
      commit("setPatentSearchQuery", newQuery);
    },
    async resetPatentSearchQuery({ commit }) {
      commit("resetPatentSearchQuery");
    }
  },
  mutations: {
    setCurrentPatent: (state, payload) => (state.currentPatent = payload),
    setCurrentResearchOrganization: (state, payload) =>
      (state.currentResearchOrganization = payload),
    setResearchOrganization: (state, payload) =>
      (state.currentResearchOrganization = payload),
    setPatentList: (state, payload) => (state.patentList = payload),
    setROList: (state, payload) => (state.ROList = payload),
    startPatentSearch: state => (state.patentSearchStarted = true),
    startROSearch: state => (state.ROSearchStarted = true),
    setPatentSearchQuery: (state, payload) => (state.patentSearchQuery = payload),
    resetPatentSearchQuery: state => (state.patentSearchQuery = ""),
    resetPatentSearchState: (state) => {
      state.patentList = {};
      state.patentSearchStarted = false;
    },
    setROListChangeVar: (state, payload) => (state.ROListChangeVar = payload)
  },
  getters: {
    currentPatent: state => state.currentPatent,
    currentResearchOrganization: state => state.currentResearchOrganization,
    patentList: state => state.patentList,
    ROList: state => state.ROList,
    patentSearchStarted: state => state.patentSearchStarted,
    ROSearchStarted: state => state.ROSearchStarted,
    InventorList: state => state.InventorList,
    patentSearchQuery: state => state.patentSearchQuery,
    SpinoffList: state => state.SpinoffList,
    ROListChangeVar: state => state.ROListChangeVar
  }
};
