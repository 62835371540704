var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"data-table mt-1 ml-3 mr-3 elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.searchResults,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"table-row",attrs:{"align":"center"}},[_c('v-col',[_c('router-link',{staticClass:"result-title text",attrs:{"to":{ name: 'Patent', params: { objectID: item.objectID } }}},[_c('p',[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)]}},{key:"item.industry",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"slot-container text mt-4"},[_vm._v(_vm._s(_vm.listToString(item.industry)))])]}},{key:"item.inventors",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"slot-container text mt-4"},[_vm._v(" "+_vm._s(_vm.listToString(item.inventors))+" ")])]}},{key:"item.climateTech",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"slot-container text text-center mt-4"},[_vm._v(" "+_vm._s(item.climateTech)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"slot-container text status mt-4",class:item.status == 'Grant' ? 'green-text' : 'blue-text'},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }