<template>
  <v-data-table
    :headers="tableHeaders"
    :items="searchResults"
    :items-per-page="10"
    class="data-table mt-1 ml-3 mr-3 elevation-1"
    hide-default-footer
  >
    <template v-slot:[`item.title`]="{ item }">
      <v-row align="center" class="table-row">
        <v-col cols="3">
          <v-img :src="item.universityImage" class="image mt-4 mb-4"></v-img>
        </v-col>
        <v-col>
          <router-link
            :to="{ name: 'Patent', params: { objectID: item.objectID } }"
            class="result-title text"
          >
            <p>
              {{ item.title }}
            </p>
          </router-link>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.industry`]="{ item }">
      <p class="slot-container text mt-4">{{ listToString(item.industry) }}</p>
    </template>
    <template v-slot:[`item.owner`]="{ item }">
      <p class="slot-container text mt-4">{{ item.owner }}</p>
    </template>
    <template v-slot:[`item.inventors`]="{ item }">
      <p class="slot-container text mt-4">
        {{ listToString(item.inventors) }}
      </p>
    </template>
    <template v-slot:[`item.climateTech`]="{ item }">
      <p class="slot-container text text-center mt-4">
        {{ item.climateTech }}
      </p>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <p
        class="slot-container text status mt-4"
        :class="item.status == 'Grant' ? 'green-text' : 'blue-text'"
      >
        {{ item.status }}
      </p>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

const headers = [
  {
    text: "Technology Overview",
    align: "start",
    sortable: false,
    value: "title"
  },
  {
    text: "Industry",
    value: "industry",
    sortable: false
  },
  { text: "Owner", value: "owner", sortable: false },
  { text: "Inventors", value: "inventors", sortable: false },
  { text: "Priority", value: "priority", sortable: false },
  { text: "Expiry", value: "expiry", sortable: false },
  { text: "Climate Tech", value: "climateTech", sortable: false },
  { text: "Status", value: "status", sortable: false }
];

export default {
  name: "SearchResults",
  data() {
    return {
      tableHeaders: headers
    };
  },
  computed: {
    ...mapGetters({
      searchResults: "patentList"
    })
  },
  methods: {
    listToString(inputList) {
      let outputString = "";
      inputList.forEach(item => {
        outputString = outputString.concat(`${item}, `);
      });
      return this.removeLastComma(outputString);
    },
    removeLastComma(value) {
      return value.slice(0, -2);
    }
  }
};
</script>

<style lang="scss">
.search-result {
  .table-row {
    min-height: 120px;
    .result-title {
      font-weight: 500;
      text-decoration: none;
    }
  }
  .image {
    max-width: 64px;
  }
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .status {
    font-weight: 500;
  }
}
.slot-container {
  min-width: 80px;
}
.data-table {
  min-width: 100%;
}
</style>
