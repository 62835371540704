<template>
  <v-container class="container pa-16" fluid>
    <v-row v-if="isLoading">
      <p>Loading ...</p>
    </v-row>
    <v-row v-else align-content="center">
      <v-col cols="9" class="pr-16">
        <v-card class="pa-8">
          <v-row class="ml-n1 mb-4">
            <v-icon left color="primary" @click="$router.go(-1)">
              mdi-arrow-left
            </v-icon>
          </v-row>
          <h2 class="mb-2">{{ patent.title }}</h2>
          <v-row>
            <v-col class="ma-4 ml-0">
              <p class="fact-title mb-1 accent-text">Priority Date</p>
              <p v-if="patent.priority">
                {{ patent.priority }}
              </p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="ma-4">
              <p class="fact-title mb-1 accent-text">Pending Jurisdictions</p>
              <p class="mb-2" v-if="patent.pendingJurisdications.length > 0">
                {{ listToString(patent.pendingJurisdications) }}
              </p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="ma-4">
              <p class="fact-title mb-1 accent-text">Granted Jurisdictions</p>
              <p class="mb-2" v-if="patent.grantedJurisdications.length > 0">
                {{ listToString(patent.grantedJurisdications) }}
              </p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="mt-4 mb-4">
              <p class="fact-title mb-1 accent-text">Status</p>
              <p v-if="patent.status.length > 0">{{ patent.status }}</p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="mt-4 mb-4">
              <p class="fact-title mb-1 accent-text">TRL</p>
              <p v-if="patent.technologyReadiness.length > 0">
                {{ listToString(patent.technologyReadiness) }}
              </p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="mt-4 mb-4">
              <p class="fact-title mb-1 accent-text">Industry</p>
              <p v-if="patent.industry.length > 0">
                {{ listToString(patent.industry) }}
              </p>
              <p v-else>n.a</p>
            </v-col>
            <v-col class="ma-4">
              <p class="fact-title mb-1 accent-text">Climate Tech</p>
              <p v-if="patent.climateTech.length > 0">
                {{ patent.climateTech }}
              </p>
              <p v-else>n.a</p>
            </v-col>
          </v-row>
          <p class="text-justify mb-8">{{ patent.summary }}</p>

          <h3 class="mb-2">Benefits</h3>
          <ol class="mb-8">
            <li v-for="benefit in patent.benefits" :key="benefit">
              {{ benefit }}
            </li>
          </ol>

          <h3 class="mb-2">Possible Applications</h3>
          <span>
            <p class="text-justify">
              {{ listToString(patent.applicationFields) }}
            </p>
          </span>

          <h3 class="mt-8 mb-2">Background</h3>
          <span>
            <p class="text-justify">
              {{ patent.backgroundProblem }}
            </p>
          </span>

          <h3 class="mt-8 mb-2">Technical Description</h3>
          <span>
            <p class="text-justify">
              {{ patent.solution }}
            </p>
          </span>

          <v-dialog v-model="showPatentDrawing" width="800">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-img :src="patent.patentDrawings" height="320" width="200">
                </v-img>
                <div style="margin-left: 164px">
                  <v-icon>mdi-magnify-plus-outline</v-icon>
                </div>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <v-img :src="patent.patentDrawings"></v-img>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-btn class="mt-8" outlined color="accent" v-if="patent.pdf">
            <v-icon left> mdi-link-variant </v-icon>
            <a
              :href="patent.pdf"
              style="color: #ff054a; text-decoration: none"
              target="_blank"
              >navigate to schemantics</a
            >
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8">
          <h3 class="mb-2">Contact Us</h3>
          <p class="mb-1">{{ patent.contact.name }}</p>
          <p class="mb-1">{{ patent.contact.occupation }}</p>
          <p class="mb-1">
            <v-icon left color="primary"> mdi-phone </v-icon>
            {{ patent.contact.phone }}
          </p>
          <v-btn class="mt-4" color="accent" v-if="patent.contact.email">
            <v-icon left> mdi-email </v-icon>
            <a
              :href="`mailto:${patent.contact.email}`"
              style="color: #fff; text-decoration: none"
              target="_blank"
              >Request more information</a
            >
          </v-btn>
        </v-card>

        <v-card class="mt-8 pa-8">
          <h3 class="mb-2">Patent Holder</h3>
          <p class="mb-2">
            {{ patent.owner }}
          </p>
          <p class="mb-2" v-if="patent.publicationNumbers">
            Publication Number: {{ patent.publicationNumbers[0] }}
          </p>
          <!-- <v-img :src="patent.universityImage" height="64" width="64"></v-img> -->
        </v-card>

        <v-card class="mt-8 pa-8" v-if="patent.inventors.length > 1">
          <h3 class="mb-2" v-if="patent.inventors.length > 1">Inventors</h3>
          <h3 class="mb-2" v-else>Inventor</h3>
          <p
            class="short-text"
            v-for="inventor in patent.inventors"
            :key="inventor"
          >
            {{ inventor }}
          </p>
        </v-card>

        <v-card class="mt-8 pa-8">
          <h3 class="mb-2">Technology Readiness</h3>
          <p class="mb-2">
            Level: {{ listToString(patent.technologyReadiness) }}
          </p>
          <p>{{ patent.TRLdescription }}</p>
        </v-card>

        <v-card class="mt-8 pa-8">
          <h3 class="mb-2">Possible Cooperation</h3>
          <p
            v-for="transferType in patent.transferType"
            :key="transferType"
            class="mb-2"
          >
            <v-icon left color="primary"> mdi-check-circle-outline </v-icon>
            {{ transferType }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <!-- TODO: case for isLoading = false && patent === undefined / empty -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PATENT_STATUS from "@/models/PatentStatus";

export default {
  name: "Patent",
  props: ["objectID"],
  data() {
    return {
      isLoading: true,
      showPatentDrawing: false,
    };
  },
  created() {
    this.loadPatent();
  },
  computed: {
    ...mapGetters({ patent: "currentPatent" }),
  },
  methods: {
    ...mapActions(["fetchPatent"]),
    async loadPatent() {
      await this.fetchPatent(this.objectID);
      this.isLoading = false;
    },
    /**
     * Reference to the business logic for this method
     * https://www.epo.org/searching-for-patents/helpful-resources/first-time-here/definitions_de.html
     */
    propertyRightsField(applicationNumbers) {
      let pendingCountries = [];
      let approvedCountries = [];
      applicationNumbers.forEach((applicationNumber) => {
        const status = this.getPatentStatus(applicationNumber);
        const countryCode = applicationNumber.substring(0, 2);
        if (status == PATENT_STATUS.B) {
          approvedCountries.push(countryCode);
        } else if (status == PATENT_STATUS.A) {
          pendingCountries.push(countryCode);
        }
      });
      pendingCountries = this.filterDuplicates(
        pendingCountries,
        approvedCountries
      );
      const pendingString = this.listToString(pendingCountries)
        ? this.listToString(pendingCountries)
        : "none";
      const approvedString = this.listToString(approvedCountries)
        ? this.listToString(approvedCountries)
        : "none";
      return `<p class="mb-2"> pending: ${pendingString} </p><p> approved: ${approvedString} </p>`;
    },
    getPatentStatus(applicationNumber) {
      const length = applicationNumber.length;
      const lastChar = applicationNumber.charAt(length - 1);
      const secondLastChar = applicationNumber.charAt(length - 2);
      if (lastChar == "A" || secondLastChar == "A") {
        return PATENT_STATUS.A;
      } else if (lastChar == "B" || secondLastChar == "B") {
        return PATENT_STATUS.B;
      } else {
        return undefined;
      }
    },
    /**
     * Since the status of patents in countries is currently inconsistent,
     * you have to manually remove all the countries from
     * pending that already are in approved
     */
    filterDuplicates(oldList, newList) {
      const newSet = new Set(newList);
      return oldList.filter((item) => !newSet.has(item));
    },
    listToString(inputList) {
      let outputString = "";
      inputList.forEach((item) => {
        outputString = outputString.concat(`${item}, `);
      });
      return this.removeLastComma(outputString);
    },
    removeLastComma(value) {
      return value.slice(0, -2);
    },
    countryCount(patent) {
      const applicationCountries = patent.applicationNumbers.map((country) =>
        country.substring(0, 2)
      );
      const grantCountries = patent.grantNumber.map((country) =>
        country.substring(0, 2)
      );
      const uniqueCountries = new Set([
        ...applicationCountries,
        ...grantCountries,
      ]);
      return uniqueCountries.size;
    },
  },
};
</script>

<style lang="scss">
b {
  color: rgba(0, 0, 0, 0.87);
}
.short-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 32px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fact-title {
  font-weight: 500;
}
</style>
