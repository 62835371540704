<template>
  <span class="text-center">
    <v-menu open-on-hover top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          {{ name }}
          <v-icon> mdi-chevron-down </v-icon>
        </span>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <!-- <component :is="item.icon"></component> -->
          <router-link to="">{{ item.name }}</router-link>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  name: "DropdownItem",
  props: ["nameProp", "itemsProp"],
  data() {
    return {
      name: this.nameProp,
      items: this.itemsProp,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
span {
  color: $color-primary;
}
</style>
