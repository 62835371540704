<template>
  <v-app-bar color="white" class="navigation-bar" app>
    <v-container class="py-0 fill-height">
      <v-row class="pt-2" align="center">
        <v-col class="mr-4" @click="resetState">
          <v-row align="center">
            <v-img
              src="@/assets/wre.png"
              max-height="28px"
              max-width="28px"
              class="logo"
            ></v-img>
            <router-link :to="{ name: 'Home' }">
              <h3 class="ml-2">PatentPlus</h3>
            </router-link>
          </v-row>
        </v-col>
        <v-col v-for="menuItem in menu" :key="menuItem.name">
          <span v-if="!menuItem.dropdown">
            <router-link :to="{ name: menuItem.name }">
              <!-- <component :is="menuItem.icon"></component> -->
              <p class="text-center mb-0">
                {{ menuItem.name }}
              </p>
            </router-link>
          </span>
          <span v-else>
            <component
              :is="menuItem.dropdown"
              v-bind="{
                nameProp: menuItem.name,
                itemsProp: menuItem.items,
              }"
            >
            </component>
          </span>
        </v-col>
        <v-col cols="2" offset="2">
          <v-row>
            <span class="text-center">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <profile-icon></profile-icon>
                    My Profile
                    <v-icon> mdi-chevron-down </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in myProfileItems"
                    :key="index"
                  >
                    <router-link to="" v-if="item.type != 'signout'">
                      {{ item.name }}
                    </router-link>
                    <!-- empty href for link style: https://stackoverflow.com/a/7676563 -->
                    <a href="javascript:;" @click="signOut" v-else>
                      {{ item.name }}
                    </a>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
import ProfileIcon from "@/components/icons/ProfileIcon";
import { Auth } from "aws-amplify";

export default {
  name: "Navigation",
  props: ["items"],
  components: {
    ProfileIcon,
  },
  data() {
    return {
      menu: this.items,
      currentItem: this.items[0],
      myProfileItems: [
        {
          name: "Profile",
        },
        {
          name: "Settings",
        },
        {
          name: "Sign out",
          type: "signout",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setActiveTabToInitialTab",
      "resetApplicationState",
      "logout",
    ]),
    async resetState() {
      await this.resetApplicationState();
      await this.setActiveTabToInitialTab();
    },
    async signOut() {
      try {
        await Auth.signOut({ global: true }).then(() => {
          this.logout();
          this.$router.push("/");
        });
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  height: 72px !important;
}
a {
  text-decoration: none;
}
.logo {
  border-radius: $border-radius;
}
.icon-primary {
  width: 28px;
  color: $color-primary !important;
}
.text-primary {
  color: $color-primary;
}
</style>
