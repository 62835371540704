import UNIQUE_VALUES from "@/store/uniqueValues.json";

export default {
  state: {
    uniqueClassifications: UNIQUE_VALUES.cpcClassification,
    uniqueCountries: UNIQUE_VALUES.countries,
    uniqueInventors: UNIQUE_VALUES.inventors,
    uniqueOwner: UNIQUE_VALUES.owner,
    uniqueROCountries: UNIQUE_VALUES.ROcountries,
    uniqueIndustry: UNIQUE_VALUES.industry,
    activePatentFilters: {
      status: [],
      cpcClassification: [],
      countries: [],
      owner: [],
      dates: [],
      inventors: [],
      industry: [],
      technologyReadiness: [],
      climateTech: []
    },
    activeResearchOrganizationFilters: {
      country: ""
    }
  },
  actions: {
    async updateCurrentPatentFilterValues({ commit }, patentList) {
      let cpcClassification = [];
      let countries = [];
      let owner = [];
      let inventors = [];
      patentList.forEach(patent => {
        patent.countries.forEach(country => countries.push(country));
        patent.inventors.forEach(inventor => inventors.push(inventor));
        cpcClassification.push(patent.cpcClassification);
        owner.push(patent.owner);
      });
      // Make payload unique before passing into mutation
      commit("setUniqueClassifications", [...new Set(cpcClassification)]);
      commit("setUniqueCountries", [...new Set(countries.filter(String))]); // removes empty strigns
      commit("setUniqueOwner", [...new Set(owner)]);
      commit("setUniqueInventors", [...new Set(inventors)]);
    },
    async updateStatusFilter({ commit }, filterValues) {
      commit("setActiveStatus", filterValues);
    },
    async updateClassificationFilter({ commit }, filterValues) {
      commit("setActiveClassifications", filterValues);
    },
    async updateCountryFilter({ commit }, filterValues) {
      commit("setActiveCountries", filterValues);
    },
    async updateOwnerFilter({ commit }, filterValues) {
      commit("setActiveOwner", filterValues);
    },
    async updateDateFilter({ commit }, filterValues) {
      commit("setActiveDates", filterValues);
    },
    async updateApplicationFieldFilter({ commit }, filterValues) {
      commit("setActiveApplicationFields", filterValues);
    },
    async updateTechnologyReadinessFilter({ commit }, filterValues) {
      commit("setActiveTechnologyReadiness", filterValues);
    },
    async updateInventorFilter({ commit }, filterValues) {
      commit("setActiveInventors", filterValues);
    },
    async updateIndustryFilter({ commit }, filterValues) {
      commit("setActiveIndustry", filterValues);
    },
    async updateClimateTechFilter({ commit }, filterValues) {
      commit("setActiveClimateTech", filterValues);
    },
    async updateROCountry({ commit }, filterValues) {
      commit("setActiveROCountry", filterValues);
    }
  },
  mutations: {
    setUniqueClassifications: (state, payload) =>
      (state.uniqueClassifications = payload),
    setUniqueCountries: (state, payload) => (state.uniqueCountries = payload),
    setUniqueOwner: (state, payload) => (state.uniqueOwner = payload),
    setUniqueInventors: (state, payload) => (state.uniqueInventors = payload),
    setActiveStatus: (state, payload) =>
      (state.activePatentFilters.status = payload),
    setActiveClassifications: (state, payload) =>
      (state.activePatentFilters.cpcClassification = payload),
    setActiveCountries: (state, payload) =>
      (state.activePatentFilters.countries = payload),
    setActiveOwner: (state, payload) =>
      (state.activePatentFilters.owner = payload),
    setActiveDates: (state, payload) =>
      (state.activePatentFilters.dates = payload),
    setActiveApplicationFields: (state, payload) =>
      (state.activePatentFilters.applicationFields = payload),
    setActiveTechnologyReadiness: (state, payload) =>
      (state.activePatentFilters.technologyReadiness = payload),
    setActiveInventors: (state, payload) =>
      (state.activePatentFilters.inventors = payload),
    setActiveIndustry: (state, payload) =>
      (state.activePatentFilters.industry = payload),
    setActiveClimateTech: (state, payload) =>
      (state.activePatentFilters.climateTech = payload),
    setActiveROCountry: (state, payload) =>
      (state.activeResearchOrganizationFilters.country = payload)
  },
  getters: {
    uniqueClassifications: state => state.uniqueClassifications,
    uniqueOwner: state => state.uniqueOwner,
    uniqueCountries: state => state.uniqueCountries,
    uniqueInventors: state => state.uniqueInventors,
    uniqueROCountries: state => state.uniqueROCountries,
    uniqueIndustry: state => state.uniqueIndustry,
    activePatentFilters: state => state.activePatentFilters,
    activeResearchOrganizationFilters: state =>
      state.activeResearchOrganizationFilters
  }
};
