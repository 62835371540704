<template>
  <v-app id="main">
    <navigation :items="items" v-if="!isLogin"></navigation>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/core/Navigation";
import MarketplaceIcon from "@/components/icons/MarketplaceIcon";
import MatchmakingIcon from "@/components/icons/MatchmakingIcon";
import BenchmarkingIcon from "@/components/icons/BenchmarkingIcon";
import TrendsIcon from "@/components/icons/TrendsIcon";
import WebinarIcon from "@/components/icons/WebinarIcon";
import BlogIcon from "@/components/icons/BlogIcon";
import ReportsIcon from "@/components/icons/ReportsIcon";
import AcademyIcon from "@/components/icons/AcademyIcon";
import DropdownItem from "@/components/navigation/DropdownItem";

var menuItems = [
  {
    name: "Marketplace",
    icon: MarketplaceIcon,
  },
  {
    name: "Matchmaking",
    icon: MatchmakingIcon,
  },
  {
    name: "Benchmarking",
    icon: BenchmarkingIcon,
  },
  {
    name: "Trends",
    icon: TrendsIcon,
  },
  {
    name: "Content",
    dropdown: DropdownItem,
    items: [
      {
        name: "Webinars",
        icon: WebinarIcon,
      },
      {
        name: "Blog",
        icon: BlogIcon,
      },
      {
        name: "Reports",
        icon: ReportsIcon,
      },
      {
        name: "Academy",
        icon: AcademyIcon,
      },
    ],
  },
];

export default {
  name: "App",
  components: {
    Navigation,
  },
  data() {
    return {
      items: menuItems,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "Login";
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  overflow: hidden;
  overflow-y: hidden;
}
#main {
  height: 100vh !important;
}

.container {
  background: #f8f8fd !important;
}
</style>
