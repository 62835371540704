/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:610cf1c8-0897-4f34-adce-052312ae36fc",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_rIe8hqZJF",
    "aws_user_pools_web_client_id": "21m1skemobs12d9b1q3o4nhe3g",
    "oauth": {}
};


export default awsmobile;
