import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Patent from "../views/Patent.vue";
import ResearchOrganization from "../views/ResearchOrganization.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    redirect: "/"
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/patent/:objectID",
    name: "Patent",
    component: Patent,
    props: true
  },
  {
    path: "/research-organization/:id",
    name: "Research-Organization",
    component: ResearchOrganization,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
