<template>
  <v-container fluid class="pt-0 mt-2">
    <technology-transfer class="ml-n6 mr-n6"></technology-transfer>
    <!-- Currently "Patents" -->
    <div class="mt-12 ml-10 mr-10 pt-6 pb-6" v-if="activeTabIndex == 0">
      <div v-if="!patentSearchStarted">
        <div>
          <p class="display-1 text-center">
            PatentPlus Technology Insights & Intelligence
          </p>
          <p class="subtitle-1 text-center">
            Get access to customized technology recommendations and global
            trends.
          </p>
        </div>
        <v-row class="mt-5">
          <v-col cols="4">
            <recommended-patents></recommended-patents>
          </v-col>
          <v-col cols="4">
            <trending-patents></trending-patents>
          </v-col>
          <v-col cols="4">
            <trending-organizations></trending-organizations>
          </v-col>
        </v-row>
      </div>
      <div v-else class="search-result mb-6">
        <v-row justify="space-between">
          <v-col cols="3" class="ml-2">
            <p v-if="patentList.length > 1">
              Found {{ patentList.length }} available patents
            </p>
            <p v-else-if="patentList.length === 1">
              Found {{ patentList.length }} available patent
            </p>
            <p v-else>There are no matching patents for your search</p>
          </v-col>
        </v-row>
        <v-row>
          <!-- TODO: show empty state when no search results are available -->
          <v-col cols="12" class="pl-0 pb-0">
            <search-results></search-results>
          </v-col>
          <v-col cols="12" class="pl-0 pt-0 ml-3">
            <dummy-search-results
              v-if="patentList.length >= 5"
            ></dummy-search-results>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Currently "Research Organizations" -->
    <div class="mt-12 mb-4 ml-10 mr-10" v-if="activeTabIndex == 1">
      <div v-if="ROSearchStarted">
        <v-row class="mt-8">
          <v-col cols="3">
            <p>Found {{ ROList.length }} available research organizations</p>
          </v-col>
        </v-row>
        <v-row :key="ROListKey">
          <v-col cols="3" v-for="item in ROList" :key="item.objectID">
            <research-organization :item="item"></research-organization>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Currently "Inventors" -->
    <div class="mt-12 mb-4 ml-10 mr-10" v-if="activeTabIndex == 2">
      <v-row class="mt-8">
        <v-col cols="3">
          <p>Found 620+ relevant inventors</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="item in InventorList" :key="item.name">
          <inventor :item="item"></inventor>
        </v-col>
      </v-row>
      <v-row>
        <dummy-inventors cols="12" class="mt-8 ml-3"></dummy-inventors>
      </v-row>
    </div>
    <!-- Currently "Spin-Offs" -->
    <div class="mt-12 mb-4 ml-10 mr-10" v-if="activeTabIndex == 3">
      <v-row class="mt-8">
        <v-col cols="3">
          <p>Found 270+ relevant spin-offs</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="item in SpinoffList" :key="item.name">
          <spinoff :item="item"></spinoff>
        </v-col>
      </v-row>
      <v-row>
        <dummy-spinoffs cols="12" class="mt-8 ml-3"></dummy-spinoffs>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TechnologyTransfer from "@/components/TechnologyTransfer.vue";
import SearchResults from "@/components/search/SearchResults";
import RecommendedPatents from "@/components/dummy/RecommendedPatents.vue";
import TrendingPatents from "@/components/dummy/TrendingPatents.vue";
import TrendingOrganizations from "@/components/dummy/TrendingOrganizations.vue";
import ResearchOrganization from "@/components/search/ResearchOrganization.vue";
import Inventor from "@/components/search/Inventor.vue";
import Spinoff from "@/components/search/Spinoff.vue";
import DummySearchResults from "@/components/dummy/SearchResults.vue";
import DummyInventors from "@/components/dummy/Inventors.vue";
import DummySpinoffs from "@/components/dummy/Spinoffs.vue";

export default {
  name: "Home",
  components: {
    TechnologyTransfer,
    SearchResults,
    DummySearchResults,
    DummyInventors,
    DummySpinoffs,
    RecommendedPatents,
    TrendingPatents,
    TrendingOrganizations,
    ResearchOrganization,
    Inventor,
    Spinoff,
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapGetters([
      "patentSearchStarted",
      "ROSearchStarted",
      "patentList",
      "ROList",
      "InventorList",
      "SpinoffList",
      "activeTabIndex",
      "ROListChangeVar",
    ]),
    // fixes unreflected array changes, copied from here: https://stackoverflow.com/a/54367510
    ROListKey() {
      return "ROListChangeVar" + this.ROListChangeVar;
    },
  },
};
</script>
