<template>
  <div>
    <v-tabs v-model="activeTab" background-color="transparent" centered>
      <v-tab
        v-for="(tab, index) in tabs"
        class="tab disabled pl-12 pr-12"
        active-class="active"
        :key="tab.name"
        @click="_setActiveTabIndex(index)"
      >
        <span>{{ tab.name }}</span>
      </v-tab>
    </v-tabs>
    <v-card elevation="0">
      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabs" :key="tabs.indexOf(tab)">
          <v-card flat class="ma-5">
            <component :is="tab.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TabList",
  props: ["items"],
  data() {
    return {
      tabs: this.items,
    };
  },
  created() {
    this.setInitialTab(this.tabs[0]);
  },
  computed: {
    ...mapGetters(["activeTabIndex"]),
    activeTab: {
      get() {
        return this.$store.getters.activeTab;
      },
      set(newTab) {
        this.setActiveTab(newTab);
      },
    },
  },
  methods: {
    ...mapActions([
      "setActiveTabIndex",
      "setActiveTab",
      "setInitialTab",
      "resetApplicationState",
    ]),
    async _setActiveTabIndex(index) {
      await this.resetApplicationState();
      await this.setActiveTabIndex(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  border-radius: 0px;
}
.tab:nth-of-type(2) {
  border-top-left-radius: $border-radius;
}
.tab:last-child {
  border-top-right-radius: $border-radius;
}
.active {
  background: #fff !important;
}
.active span {
  color: $color-primary !important;
}
.disabled {
  background: rgba(255, 255, 255, 0.3);
}
.disabled span {
  color: rgba(255, 255, 255, 0.6);
}
</style>
