<template>
  <v-card height="328px">
    <v-card-text class="pb-0">
      <h3>Trending Patents</h3>
      <p>
        Currently popular patents
      </p>
    </v-card-text>
    <v-img
      src="@/assets/searchresults.png"
      class="background align-end"
      gradient="to bottom, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)"
      height="254px"
      position="left"
    >
      <v-card-text class="premium-preview">
        <v-row class="d-flex mb-12">
          <v-col cols="12" class="text-center p-0">
            <trending-patent-icon class="mb-5"></trending-patent-icon>
            <p>
              You are currently not a premium user
            </p>
            <v-btn text color="accent" class="text-center">
              Upgrade to premium
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-img>
  </v-card>
</template>

<script>
import TrendingPatentIcon from "@/components/icons/TrendingPatentIcon";
export default {
  name: "TrendingPatents",
  components: {
    TrendingPatentIcon
  }
};
</script>

<style lang="scss" scoped>
.background {
  background-repeat: no-repeat;
}
.premium-preview {
  p {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
